import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

// eslint-disable-next-line
export const ActivityPageTemplate = ({ title, subtitle, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    window.scrollTo(0, 0);
    setTimeout(() => {
      const [, idToBeScrolledInto] = window.location.href.split('#');
      const encodedIdToBeScrolledInto = decodeURIComponent(idToBeScrolledInto);
      const heading = document.getElementById(encodedIdToBeScrolledInto);
      if (heading) {
        let position = heading.getBoundingClientRect();
        window.scrollTo({
          top: position.top + window.scrollY - 52,
          left: position.left,
          behavior: "smooth"
        });
      }
    }, 350);
  }
  return (
    <div>
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url('/img/activity.jpg')`,
          filter: "contrast(.5)",
          backgroundPosition: "center"
        }}
      >
        <h1
          className="has-text-weight-bold is-size-1 has-text-centered"
          style={{
            color: "white",
            padding: "1rem",
          }}
        >
          {title}
        </h1>
      </div>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {subtitle}
              </h2>
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ActivityPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const ActivityPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ActivityPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        subtitle={post.frontmatter.subtitle}
        content={post.html}
      />
    </Layout>
  );
};

ActivityPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ActivityPage;

export const activityPageQuery = graphql`
  query ActivityPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title,
        subtitle
      }
    }
  }
`;
